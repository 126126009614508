import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Github from "../images/github.png"
import LinkedIn from "../images/linkedin.svg"
import Emoji from "./emoji"

export default function greet({ handleClick }) {
  return (
    <div className="mainWrapper">
      <div className="greetWrapper">
        <div className="text-hi">
          Hi<span id="dot">.</span>
        </div>
        <div>
          <div className="normal">I am</div>
          <div className="normal">Marcial</div>
        </div>
      </div>
      <div className="desc">
        <p className="role">Developer | Thinker | Passionate</p>
        <p className="job" id="links">
          <AniLink to="/about" paintDrip hex="#aad8ff">
            <Emoji label="sheep" symbol="💻" /> <span>About</span>
          </AniLink>
          &bull;
          <AniLink paintDrip to="/projects" hex="#aad8ff">
            <Emoji label="sheep" symbol="📍" /> <span>Projects</span>
          </AniLink>
        </p>
        <p className="social">
          <a
            href="https://github.com/supermarsh"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={Github} alt="git" width="24" height="24" />
          </a>
          <a
            href="https://www.linkedin.com/in/marcialnorte/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={LinkedIn} alt="linkedin" width="24" height="24" />
          </a>
        </p>
      </div>
    </div>
  )
}
